'use client'

import { useSelector } from 'react-redux'

import { Spacer } from '@vinted/web-ui'

import {
  ADVERTISING_URL,
  APP_LANDING_URL,
  BUSINESS_ACCOUNTS_GUIDE_URL,
  BUSINESS_ACCOUNTS_HOMEPAGE,
  HELP_BUYING_URL,
  HELP_CENTER_URL,
  HELP_SAFETY_URL,
  HELP_SELLING_URL,
  HOW_IT_WORKS_URL,
  INFOBOARD_URL,
  ITEM_VERIFICATION_URL,
  JOBS_URL,
  JOBS_URL_NEW,
  MAIN_NAVIGATION_ABOUT_TAB_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useCompanyWeb from 'hooks/useCompanyWeb'
import { clickEvent } from 'libs/common/event-tracker/events'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import { Screen } from 'constants/tracking/screens'

import LinkSectionGroup from './LinkSectionGroup'
import LinkSectionItem from './LinkSectionItem'

type Props = {
  isBusinessInfoLinksEnabled: boolean
}

const LinksSection = ({ isBusinessInfoLinksEnabled }: Props) => {
  const {
    isPressMenuEnabled,
    isSustainabilityMenuEnabled,
    newsroomUrl,
    sustainabilityLandingPageUrl,
  } = useCompanyWeb()

  const isJobsPageEnabled = useSelector(getIsFeatureSwitchEnabled('jobs_page'))
  const isItemVerificationPageEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_item_verification_page_link'),
  )
  const isNewJobsUrlEnabled = useSelector(getIsFeatureSwitchEnabled('new_jobs_career_portal'))
  const jobsUrl = isNewJobsUrlEnabled ? JOBS_URL_NEW : JOBS_URL

  const translate = useTranslate('footer')
  const { track } = useTracking()

  function handleSustainabilityClick() {
    track(
      clickEvent({
        target: ClickableElement.SustainabilityPageLink,
        targetDetails: 'navigation_web',
      }),
    )
  }

  function handleAboutClick() {
    track(
      clickEvent({
        target: ClickableElement.AboutVinted,
        screen: Screen.MainFooter,
      }),
    )
  }

  function handleHelpCenterClick() {
    track(
      clickEvent({
        target: ClickableElement.HelpCenter,
        screen: Screen.MainFooter,
      }),
    )
  }

  return (
    <ul className="main-footer__links-section">
      <LinkSectionGroup title={translate('company.title')}>
        <LinkSectionItem
          text={translate('company.items.about')}
          url={MAIN_NAVIGATION_ABOUT_TAB_URL}
          onClick={handleAboutClick}
        />
        {isJobsPageEnabled && (
          <LinkSectionItem text={translate('company.items.jobs')} url={jobsUrl} />
        )}
        {isSustainabilityMenuEnabled && (
          <LinkSectionItem
            text={translate('company.items.sustainability')}
            url={sustainabilityLandingPageUrl}
            onClick={handleSustainabilityClick}
          />
        )}
        {isPressMenuEnabled && (
          <LinkSectionItem text={translate('company.items.press')} url={newsroomUrl} />
        )}
        <LinkSectionItem text={translate('company.items.advertising')} url={ADVERTISING_URL} />
      </LinkSectionGroup>
      <LinkSectionGroup title={translate('discover.title')}>
        <LinkSectionItem text={translate('discover.items.how_it_works')} url={HOW_IT_WORKS_URL} />
        {isItemVerificationPageEnabled && (
          <LinkSectionItem
            text={translate('discover.items.item_verification')}
            url={ITEM_VERIFICATION_URL}
          />
        )}
        <LinkSectionItem text={translate('discover.items.app')} url={APP_LANDING_URL} />
        <LinkSectionItem text={translate('discover.items.infoboard')} url={INFOBOARD_URL} />
        {isBusinessInfoLinksEnabled && (
          <>
            <LinkSectionItem
              text={translate('discover.items.pro')}
              url={BUSINESS_ACCOUNTS_HOMEPAGE}
            />
            <LinkSectionItem
              text={translate('discover.items.pro_guide')}
              url={BUSINESS_ACCOUNTS_GUIDE_URL}
            />
          </>
        )}
      </LinkSectionGroup>
      <LinkSectionGroup title={translate('help.title')}>
        <LinkSectionItem
          text={translate('help.items.centre')}
          url={HELP_CENTER_URL}
          onClick={handleHelpCenterClick}
        />
        <LinkSectionItem text={translate('help.items.selling')} url={HELP_SELLING_URL} />
        <LinkSectionItem text={translate('help.items.buying')} url={HELP_BUYING_URL} />
        <LinkSectionItem text={translate('help.items.safety')} url={HELP_SAFETY_URL} />
      </LinkSectionGroup>
      <li className="u-margin-none">
        <Spacer orientation={Spacer.Orientation.Vertical} />
      </li>
    </ul>
  )
}

export default LinksSection
