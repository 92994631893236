import { useSelector } from 'react-redux'

import { PRESS_CENTER_URL } from 'constants/routes'
import { getLocale } from 'state/intl/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

const SUSTAINABILITY_REPORT_LOCALES = ['pl', 'nl', 'de', 'it', 'es']
const PRESS_CENTER_LOCALES = ['en', 'fr', 'lt']

const getSustainablityLandingPageLocaleParam = (activeLocale: string) =>
  SUSTAINABILITY_REPORT_LOCALES.includes(activeLocale) ? `?lang=${activeLocale}` : ''

const getLocaleSubPath = (activeLocale: string) =>
  PRESS_CENTER_LOCALES.includes(activeLocale) ? `/${activeLocale}` : ''

function useCompanyWeb() {
  const isSustainabilityMenuEnabled = useSelector(
    getIsFeatureSwitchEnabled('sustainability_page_press_center_link'),
  )
  const isPressMenuEnabled = useSelector(getIsFeatureSwitchEnabled('press_menu'))
  const locale = useSelector(getLocale).substring(0, 2)
  const companyWebUrl = `${PRESS_CENTER_URL}${getLocaleSubPath(locale)}`
  const newsroomUrl = `${companyWebUrl}/newsroom`
  const sustainabilityLandingPageUrl = `${companyWebUrl}/sustainability${getSustainablityLandingPageLocaleParam(locale)}`

  return {
    isPressMenuEnabled,
    isSustainabilityMenuEnabled,
    newsroomUrl,
    sustainabilityLandingPageUrl,
  }
}

export default useCompanyWeb
